@charset "UTF-8";
:root {
  --c-light: #fff;
  --c-default: #000;
  --c-corp: #f00;
  --c-bread: #777;
  --c-lgrey: #f8f8f8;
  --c-dgrey: #ccc; }

.txtLink, .txtLinkMore {
  font-family: "NeueHaasUnicaW1G-Regular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #0073cd;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  text-decoration: none; }
  .txtLink:after, .txtLinkMore:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 12px;
    bottom: 0;
    height: 1px;
    background: #0073cd;
    transition: 0.1s linear;
    transform: scalex(0);
    transform-origin: left center; }
  .txtLink:focus, .txtLinkMore:focus, .txtLink:active, .txtLinkMore:active, .txtLink:hover, .txtLinkMore:hover {
    color: #0073cd;
    text-decoration: none; }
    .txtLink:focus:after, .txtLinkMore:focus:after, .txtLink:active:after, .txtLinkMore:active:after, .txtLink:hover:after, .txtLinkMore:hover:after {
      transform: scalex(1); }

.txtLinkMore {
  padding-right: 12px;
  position: relative; }
  .txtLinkMore:before {
    content: "";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    right: 0;
    bottom: 3px;
    font-size: 14px;
    line-height: 1; }

.linkAnimated, .linkMoreAnimated {
  color: #0270c9;
  cursor: pointer;
  font-family: "NeueHaasUnicaW1G-Regular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  text-decoration: none;
  transition: 0.1s linear;
  white-space: nowrap; }
  .linkAnimated::after, .linkMoreAnimated::after {
    background-color: #0270c9;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: 0.1s linear; }
  .linkAnimated:focus, .linkMoreAnimated:focus, .linkAnimated:active, .linkMoreAnimated:active, .linkAnimated:hover, .linkMoreAnimated:hover {
    color: #0270c9;
    text-decoration: none; }
    .linkAnimated:focus::after, .linkMoreAnimated:focus::after, .linkAnimated:active::after, .linkMoreAnimated:active::after, .linkAnimated:hover::after, .linkMoreAnimated:hover::after {
      transform: scaleX(1); }

.linkMoreAnimated {
  padding-right: 12px; }
  .linkMoreAnimated::after {
    right: 12px; }
  .linkMoreAnimated::before {
    bottom: 3px;
    content: "";
    display: block;
    font-family: FontAwesome;
    font-size: 14px;
    line-height: 1;
    position: absolute;
    right: 0; }

.landing-ageless {
  background-color: #010101;
  color: #fff;
  cursor: url("https://s3.eu-central-1.amazonaws.com/lenovo-ua-assets/portal/images/landing/ageless/svg/cursor.svg") 18 18, pointer;
  font-family: "Gotham", Helvetica, Arial, sans-serif; }
  .landing-ageless a,
  .landing-ageless button,
  .landing-ageless .link-to-top {
    cursor: url("https://s3.eu-central-1.amazonaws.com/lenovo-ua-assets/portal/images/landing/ageless/svg/cursor-active.svg") 18 18, pointer; }
  .landing-ageless main {
    margin-top: 0;
    padding-top: 0; }
  .landing-ageless .container {
    position: relative; }
    @media (min-width: 1260px) {
      .landing-ageless .container {
        max-width: calc(100% - 200px); } }
    @media all and (min-width: 1700px) {
      .landing-ageless .container {
        max-width: 1660px; } }
  .landing-ageless .header {
    background-color: transparent;
    height: 80px;
    position: relative; }
    @media (min-width: 1024px) {
      .landing-ageless .header {
        height: 100px; } }
    @media all and (min-width: 1800px) {
      .landing-ageless .header {
        height: 155px; } }
    .landing-ageless .header-container {
      height: 100%; }
      @media (max-width: 1023.98px) {
        .landing-ageless .header-container {
          align-items: center;
          display: flex;
          justify-content: space-between; } }
      @media all and (max-width: 1600px) {
        .landing-ageless .header-container {
          max-width: 100%; } }
    .landing-ageless .header .ageless-logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 54px;
      width: 140px; }
      @media (max-width: 1023.98px) {
        .landing-ageless .header .ageless-logo {
          left: 10px;
          position: absolute;
          top: 20px; } }
      @media (min-width: 576px) {
        .landing-ageless .header .ageless-logo {
          height: 70px;
          width: 187px; } }
      @media (min-width: 1024px) {
        .landing-ageless .header .ageless-logo {
          height: 94px;
          width: 250px; } }
      @media all and (min-width: 1800px) {
        .landing-ageless .header .ageless-logo {
          height: 135px;
          width: 360px; } }
    .landing-ageless .header .lenovo-logo {
      color: #fff; }
      @media (min-width: 1024px) {
        .landing-ageless .header .lenovo-logo {
          display: none; } }
      .landing-ageless .header .lenovo-logo svg {
        height: 25px;
        width: 126px; }
        @media (min-width: 576px) {
          .landing-ageless .header .lenovo-logo svg {
            height: 35px;
            width: 177px; } }
        .landing-ageless .header .lenovo-logo svg path,
        .landing-ageless .header .lenovo-logo svg polygon {
          fill: #fff; }
    .landing-ageless .header-nav {
      padding: 0; }
      @media (max-width: 1023.98px) {
        .landing-ageless .header-nav {
          background: linear-gradient(180deg, #170025 -100%, #000825 -57.96%, #001641 -23.71%, #121519 51.54%, #000000 94.13%), #FFFFFF;
          display: block;
          height: 0;
          height: 100vh;
          left: 0;
          opacity: 0;
          padding: 50px;
          position: absolute;
          top: 0;
          transition: opacity 0.2s ease-in-out;
          visibility: hidden;
          width: 100%;
          z-index: 98; } }
      @media (min-width: 1024px) {
        .landing-ageless .header-nav {
          height: 100%; } }
      @media (min-width: 768px) {
        .landing-ageless .header-nav::before, .landing-ageless .header-nav::after {
          background-color: #6c507e;
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          top: 50%; } }
      @media (min-width: 1260px) {
        .landing-ageless .header-nav::before {
          left: 0;
          width: 100px; } }
      @media all and (min-width: 1800px) {
        .landing-ageless .header-nav::before {
          width: 260px; } }
      @media (min-width: 1260px) {
        .landing-ageless .header-nav::after {
          right: 100px;
          width: 100px; } }
      @media all and (min-width: 1800px) {
        .landing-ageless .header-nav::after {
          right: 100px;
          width: 160px; } }
      .landing-ageless .header-nav.open {
        opacity: 1;
        visibility: visible; }
        .landing-ageless .header-nav.open.show .header-nav-menu-item {
          opacity: 1;
          transform: translateX(0); }
        .landing-ageless .header-nav.open.show .header-nav-lang {
          opacity: 1; }
        .landing-ageless .header-nav.open.show.hide .header-nav-menu-item {
          opacity: 0;
          transform: translateX(100px); }
        .landing-ageless .header-nav.open.show.hide .header-nav-lang {
          opacity: 0; }
        .landing-ageless .header-nav.open .header-nav-menu {
          height: 100%; }
        .landing-ageless .header-nav.open.animate .header-nav-menu-item:nth-child(2) {
          transition: opacity 0.2s 0.2s, transform 0.2s 0.2s; }
        .landing-ageless .header-nav.open.animate .header-nav-menu-item:nth-child(3) {
          transition: opacity 0.2s 0.3s, transform 0.2s 0.3s; }
        .landing-ageless .header-nav.open.animate .header-nav-menu-item:nth-child(4) {
          transition: opacity 0.2s 0.4s, transform 0.2s 0.4s; }
        .landing-ageless .header-nav.open.animate .header-nav-menu-item:nth-child(5) {
          transition: opacity 0.2s 0.5s, transform 0.2s 0.5s; }
      .landing-ageless .header-nav-menu {
        background-color: transparent;
        display: flex;
        justify-content: center;
        opacity: 1;
        overflow: visible;
        padding: 0;
        position: static;
        transform: none; }
        @media (max-width: 1023.98px) {
          .landing-ageless .header-nav-menu-item {
            opacity: 0;
            transform: translateX(-100px); }
            .landing-ageless .header-nav-menu-item:nth-child(n + 3) {
              margin-top: 44px; } }
        @media (min-width: 1024px) {
          .landing-ageless .header-nav-menu-item {
            margin-left: 30px; }
            .landing-ageless .header-nav-menu-item:nth-child(1) {
              order: 3; }
            .landing-ageless .header-nav-menu-item:nth-child(2) {
              margin-left: 0;
              order: 1; }
            .landing-ageless .header-nav-menu-item:nth-child(3) {
              order: 2; }
            .landing-ageless .header-nav-menu-item:nth-child(4) {
              order: 4; }
            .landing-ageless .header-nav-menu-item:nth-child(5) {
              order: 5; } }
        @media all and (min-width: 1600px) {
          .landing-ageless .header-nav-menu-item {
            margin-left: 60px; } }
        @media (min-width: 1024px) {
          .landing-ageless .header-nav-menu-item.header-logo {
            order: 3; } }
        .landing-ageless .header-nav-menu-item a {
          color: #fff;
          font-size: 22px;
          line-height: 1.2;
          position: relative;
          text-transform: uppercase; }
          @media (min-width: 1024px) {
            .landing-ageless .header-nav-menu-item a {
              font-size: 16px; } }
          .landing-ageless .header-nav-menu-item a:hover::after {
            width: 100%; }
          .landing-ageless .header-nav-menu-item a::after {
            background-color: #fff;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            transition: width 0.2s ease-in-out;
            width: 0; }
      .landing-ageless .header-nav-lang {
        align-items: center;
        display: flex; }
        @media (max-width: 1023.98px) {
          .landing-ageless .header-nav-lang {
            justify-content: center;
            margin-top: 20px;
            opacity: 0;
            transition: opacity 0.2s 0.8s ease-in-out; } }
        @media (min-width: 1024px) {
          .landing-ageless .header-nav-lang {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%); } }
        .landing-ageless .header-nav-lang-item {
          border-radius: 4px;
          font-size: 16px;
          line-height: 1.2;
          padding: 6px;
          text-transform: uppercase; }
          .landing-ageless .header-nav-lang-item.__active {
            background-color: #fff;
            color: #6c507e; }
          .landing-ageless .header-nav-lang-item a {
            color: #fff; }
    .landing-ageless .header .burger {
      background-color: transparent;
      border: 0;
      height: 26px;
      padding: 0;
      position: relative;
      width: 26px;
      z-index: 99; }
      @media (min-width: 768px) {
        .landing-ageless .header .burger {
          height: 35px;
          width: 40px; } }
      @media (min-width: 1024px) {
        .landing-ageless .header .burger {
          display: none; } }
      .landing-ageless .header .burger.open span:nth-child(1) {
        left: 50%;
        top: 18px;
        width: 0; }
      .landing-ageless .header .burger.open span:nth-child(2) {
        transform: rotate(45deg); }
      .landing-ageless .header .burger.open span:nth-child(3) {
        transform: rotate(-45deg); }
      .landing-ageless .header .burger.open span:nth-child(4) {
        left: 50%;
        top: 18px;
        width: 0; }
      .landing-ageless .header .burger span {
        background: #fff;
        display: block;
        height: 4px;
        left: 0;
        opacity: 1;
        position: absolute;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
        width: 100%; }
        .landing-ageless .header .burger span:nth-child(1) {
          top: 3px; }
        .landing-ageless .header .burger span:nth-child(2), .landing-ageless .header .burger span:nth-child(3) {
          top: 10px; }
          @media (min-width: 768px) {
            .landing-ageless .header .burger span:nth-child(2), .landing-ageless .header .burger span:nth-child(3) {
              top: 15px; } }
        .landing-ageless .header .burger span:nth-child(4) {
          top: 17px; }
          @media (min-width: 768px) {
            .landing-ageless .header .burger span:nth-child(4) {
              top: 26px; } }
  .landing-ageless.webp .ageless-logo {
    background-image: url("https://lenovo.ua/proxy/360_135/ageless/ageless-shooters-logo.webp"); }
  .landing-ageless.jpeg2000 .ageless-logo, .landing-ageless.jpeg .ageless-logo {
    background-image: url("https://lenovo.ua/proxy/360_135/ageless/ageless-shooters-logo.png"); }
  .landing-ageless .landing-slide {
    padding: 60px 0; }
    @media (min-width: 1024px) {
      .landing-ageless .landing-slide {
        padding: 100px 0; } }
    @media (min-width: 1260px) {
      .landing-ageless .landing-slide {
        padding: 120px 0; } }
    .landing-ageless .landing-slide-header {
      font-size: 40px;
      font-weight: 900;
      line-height: 1.2;
      margin-bottom: 60px;
      position: relative;
      text-align: center; }
      @media all and (min-width: 1600px) {
        .landing-ageless .landing-slide-header {
          font-size: 60px;
          margin-bottom: 90px; } }
      @media (min-width: 768px) {
        .landing-ageless .landing-slide-header::before, .landing-ageless .landing-slide-header::after {
          background-color: #6c507e;
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          top: 50%;
          width: 150px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-header::before, .landing-ageless .landing-slide-header::after {
          width: 150px; } }
      @media (min-width: 1260px) {
        .landing-ageless .landing-slide-header::before, .landing-ageless .landing-slide-header::after {
          width: 200px; } }
      @media all and (min-width: 1600px) {
        .landing-ageless .landing-slide-header::before, .landing-ageless .landing-slide-header::after {
          width: 250px; } }
      @media all and (min-width: 1800px) {
        .landing-ageless .landing-slide-header::before, .landing-ageless .landing-slide-header::after {
          width: 336px; } }
      .landing-ageless .landing-slide-header::before {
        left: 0; }
      .landing-ageless .landing-slide-header::after {
        right: 0; }
  .landing-ageless .landing-slide-01 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 30.49%, rgba(0, 0, 0, 0.3) 52.41%, #000 75.75%);
    padding: 70px 0;
    text-align: center; }
    @media (min-width: 576px) {
      .landing-ageless .landing-slide-01 {
        padding-top: 150px; } }
    @media (min-width: 768px) {
      .landing-ageless .landing-slide-01 {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 400px; } }
    @media (min-width: 1024px) {
      .landing-ageless .landing-slide-01 {
        background-position: top center;
        background-size: cover;
        height: 100vh;
        margin-top: -100px;
        padding-bottom: 0;
        padding-top: 350px; } }
    @media (min-width: 1260px) {
      .landing-ageless .landing-slide-01 {
        padding-top: 450px; } }
    @media all and (min-width: 1600px) {
      .landing-ageless .landing-slide-01 {
        padding-top: 65vh; } }
    @media all and (min-width: 1800px) {
      .landing-ageless .landing-slide-01 {
        margin-top: -125px; } }
    .landing-ageless .landing-slide-01-header {
      font-size: 24px;
      font-weight: 900;
      line-height: 1.1;
      margin-bottom: 20px; }
      @media all and (min-width: 375px) {
        .landing-ageless .landing-slide-01-header {
          font-size: 34px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-01-header {
          font-size: 40px;
          margin-bottom: 12px; } }
      @media (min-width: 1260px) {
        .landing-ageless .landing-slide-01-header {
          font-size: 45px; } }
      @media all and (min-width: 1600px) {
        .landing-ageless .landing-slide-01-header {
          font-size: 55px; } }
      @media all and (min-width: 1800px) {
        .landing-ageless .landing-slide-01-header {
          font-size: 70px; } }
    .landing-ageless .landing-slide-01-subheader {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 60px; }
      @media all and (min-width: 375px) {
        .landing-ageless .landing-slide-01-subheader {
          font-size: 18px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-01-subheader {
          margin-bottom: 30px; } }
      @media all and (min-width: 1600px) {
        .landing-ageless .landing-slide-01-subheader {
          font-size: 26px;
          margin-bottom: 42px; } }
    .landing-ageless .landing-slide-01 .ageless-logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 120px;
      margin-bottom: 20px;
      width: 288px; }
      @media (min-width: 768px) {
        .landing-ageless .landing-slide-01 .ageless-logo {
          height: 135px;
          margin-bottom: 50px;
          width: 365px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-01 .ageless-logo {
          display: none; } }
    .landing-ageless .landing-slide-01 .legion-logo {
      width: 120px; }
      @media (min-width: 576px) {
        .landing-ageless .landing-slide-01 .legion-logo {
          width: 150px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-01 .legion-logo {
          width: auto; } }
  @media (min-width: 768px) {
    .landing-ageless.webp .landing-slide-01 {
      background-image: url("https://lenovo.ua/proxy/1920_1010/ageless/s01-bg.webp"); } }
  .landing-ageless.webp .landing-slide-01 .ageless-logo {
    background-image: url("https://lenovo.ua/proxy/360_135/ageless/ageless-shooters-logo.webp"); }
  @media (min-width: 1024px) {
    .landing-ageless.jpeg2000 .landing-slide-01, .landing-ageless.jpeg .landing-slide-01 {
      background-image: url("https://lenovo.ua/proxy/1920_1010/ageless/s01-bg.jpg"); } }
  .landing-ageless.jpeg2000 .landing-slide-01 .ageless-logo, .landing-ageless.jpeg .landing-slide-01 .ageless-logo {
    background-image: url("https://lenovo.ua/proxy/360_135/ageless/ageless-shooters-logo.png"); }
  .landing-ageless .landing-slide-02 {
    background: linear-gradient(180deg, #010101 0%, #1b1c23 41.33%, #000 100%); }
    @media (max-width: 767.98px) {
      .landing-ageless .landing-slide-02 {
        padding-bottom: 25px; } }
    @media (max-width: 1023.98px) {
      .landing-ageless .landing-slide-02 {
        padding-top: 70px; } }
    @media (min-width: 1260px) {
      .landing-ageless .landing-slide-02 {
        padding-bottom: 45px; } }
    .landing-ageless .landing-slide-02 .team {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px; }
      .landing-ageless .landing-slide-02 .team-player {
        flex: 0 0 50%;
        margin-bottom: 70px;
        max-width: 50%; }
        @media (min-width: 1024px) {
          .landing-ageless .landing-slide-02 .team-player {
            flex: 0 0 33.33333%;
            margin-bottom: 60px;
            max-width: 33.33333%; } }
        @media all and (max-width: 1700px) {
          .landing-ageless .landing-slide-02 .team-player:last-child {
            margin-bottom: 0; } }
        @media all and (min-width: 1700px) {
          .landing-ageless .landing-slide-02 .team-player {
            flex: 0 0 25%;
            max-width: 25%; } }
        .landing-ageless .landing-slide-02 .team-player:hover .team-player-avatar::after {
          animation: shine .75s; }
        .landing-ageless .landing-slide-02 .team-player-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          padding: 0 10px; }
        .landing-ageless .landing-slide-02 .team-player-avatar {
          margin-bottom: 17px;
          overflow: hidden;
          padding-bottom: 96.812%;
          position: relative; }
          @media (min-width: 1024px) {
            .landing-ageless .landing-slide-02 .team-player-avatar {
              padding-bottom: 84.812%; } }
          .landing-ageless .landing-slide-02 .team-player-avatar::before {
            background-position: center;
            background-size: cover;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%; }
          .landing-ageless .landing-slide-02 .team-player-avatar::after {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
            content: '';
            display: block;
            height: 100%;
            left: -75%;
            position: absolute;
            top: 0;
            transform: skewX(-25deg);
            width: 50%;
            z-index: 2; }

@keyframes shine {
  100% {
    left: 125%; } }
        .landing-ageless .landing-slide-02 .team-player-name {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.2;
          margin-bottom: 20px; }
        .landing-ageless .landing-slide-02 .team-player-profession {
          display: none; }
          @media (min-width: 768px) {
            .landing-ageless .landing-slide-02 .team-player-profession {
              display: flex;
              font-size: 14px;
              line-height: 1.4;
              margin-bottom: 20px; } }
          .landing-ageless .landing-slide-02 .team-player-profession-title {
            font-weight: 700;
            margin-right: 4px; }
        .landing-ageless .landing-slide-02 .team-player-hobby {
          display: none; }
          @media (min-width: 768px) {
            .landing-ageless .landing-slide-02 .team-player-hobby {
              display: flex;
              font-size: 14px;
              line-height: 1.4;
              margin-bottom: 20px; } }
          .landing-ageless .landing-slide-02 .team-player-hobby-title {
            font-weight: 700;
            margin-right: 4px; }
        .landing-ageless .landing-slide-02 .team-player-stats {
          border-bottom: 1px solid #6c507e;
          border-top: 1px solid #6c507e;
          display: flex;
          flex-direction: column;
          margin-top: auto;
          padding: 12px 0; }
          @media (min-width: 768px) {
            .landing-ageless .landing-slide-02 .team-player-stats {
              align-items: stretch;
              flex-direction: row;
              justify-content: space-between; } }
          .landing-ageless .landing-slide-02 .team-player-stats-item {
            align-items: center;
            display: flex; }
            .landing-ageless .landing-slide-02 .team-player-stats-item:nth-child(n + 2) {
              margin-top: 10px; }
              @media (min-width: 768px) {
                .landing-ageless .landing-slide-02 .team-player-stats-item:nth-child(n + 2) {
                  margin-left: 5px;
                  margin-top: 0; } }
          .landing-ageless .landing-slide-02 .team-player-stats-icon {
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            flex: 0 0 26px;
            height: 26px;
            margin-right: 10px;
            width: 26px; }
            .landing-ageless .landing-slide-02 .team-player-stats-icon.hours {
              background-image: url("https://s3.eu-central-1.amazonaws.com/lenovo-ua-assets/portal/images/landing/ageless/svg/icon-clock.svg"); }
            .landing-ageless .landing-slide-02 .team-player-stats-icon.wins {
              background-image: url("https://s3.eu-central-1.amazonaws.com/lenovo-ua-assets/portal/images/landing/ageless/svg/icon-trophy.svg"); }
            .landing-ageless .landing-slide-02 .team-player-stats-icon.crosshair {
              background-image: url("https://s3.eu-central-1.amazonaws.com/lenovo-ua-assets/portal/images/landing/ageless/svg/icon-crosshair.svg"); }
          .landing-ageless .landing-slide-02 .team-player-stats-txt {
            line-height: 1.5; }
          .landing-ageless .landing-slide-02 .team-player-stats-title {
            font-size: 14px; }
          .landing-ageless .landing-slide-02 .team-player-stats-digits {
            font-size: 20px;
            font-weight: bold; }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.rocinrol::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/rocinrol.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.valkyria::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/valkyria.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.archer::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/archer.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.taskor::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/taskor.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.ukrop::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/ukrop.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.silentsleep::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/silentsleep.webp"); }
  .landing-ageless.webp .landing-slide-02 .team-player-avatar.hammer::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/hammer.webp"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.rocinrol::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.rocinrol::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/rocinrol.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.valkyria::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.valkyria::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/valkyria.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.archer::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.archer::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/archer.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.taskor::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.taskor::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/taskor.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.ukrop::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.ukrop::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/ukrop.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.silentsleep::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.silentsleep::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/silentsleep.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-02 .team-player-avatar.hammer::before, .landing-ageless.jpeg .landing-slide-02 .team-player-avatar.hammer::before {
    background-image: url("https://lenovo.ua/proxy/400_335/ageless/team/hammer.jpg"); }
  .landing-ageless .landing-slide-03 {
    padding-top: 25px; }
    @media (min-width: 768px) {
      .landing-ageless .landing-slide-03 {
        padding-top: 60px; } }
    @media (min-width: 1260px) {
      .landing-ageless .landing-slide-03 {
        padding-top: 105px; } }
    @media all and (min-width: 1600px) {
      .landing-ageless .landing-slide-03 {
        padding-bottom: 80px; } }
    @media (max-width: 767.98px) {
      .landing-ageless .landing-slide-03 .landing-slide-header {
        margin-bottom: 25px; } }
    .landing-ageless .landing-slide-03 p {
      font-size: 16px;
      line-height: 1.6; }
      @media (min-width: 768px) {
        .landing-ageless .landing-slide-03 p {
          font-size: 20px; } }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-03 p {
          font-size: 26px;
          line-height: 1.5; } }
    .landing-ageless .landing-slide-03 .photos {
      margin: 30px auto;
      overflow: hidden;
      text-align: center; }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-03 .photos {
          margin: 58px auto; } }
      .landing-ageless .landing-slide-03 .photos-wrapper {
        display: flex;
        flex-wrap: nowrap; }
      .landing-ageless .landing-slide-03 .photos-container {
        display: flex;
        flex-wrap: nowrap; }
      .landing-ageless .landing-slide-03 .photos picture,
      .landing-ageless .landing-slide-03 .photos img {
        height: 153px;
        margin-left: 9px;
        width: auto; }
        @media (min-width: 768px) {
          .landing-ageless .landing-slide-03 .photos picture,
          .landing-ageless .landing-slide-03 .photos img {
            height: 240px;
            margin-left: 15px; } }
        @media (min-width: 1024px) {
          .landing-ageless .landing-slide-03 .photos picture,
          .landing-ageless .landing-slide-03 .photos img {
            height: 360px;
            margin-left: 23px; } }
  .landing-ageless .landing-slide-04 {
    background: linear-gradient(180deg, #010101 0%, #1b1c23 41.33%, #000 100%); }
    @media all and (min-width: 1600px) {
      .landing-ageless .landing-slide-04 {
        padding-bottom: 80px; } }
    @media (min-width: 768px) {
      .landing-ageless .landing-slide-04 .landing-slide-header::before, .landing-ageless .landing-slide-04 .landing-slide-header::after {
        width: 120px; } }
    @media (min-width: 1024px) {
      .landing-ageless .landing-slide-04 .landing-slide-header::before, .landing-ageless .landing-slide-04 .landing-slide-header::after {
        width: 100px; } }
    @media (min-width: 1260px) {
      .landing-ageless .landing-slide-04 .landing-slide-header::before, .landing-ageless .landing-slide-04 .landing-slide-header::after {
        width: 120px; } }
    @media all and (min-width: 1700px) {
      .landing-ageless .landing-slide-04 .landing-slide-header::before, .landing-ageless .landing-slide-04 .landing-slide-header::after {
        width: 200px; } }
    .landing-ageless .landing-slide-04 .landing-slide-model {
      background-color: #242424;
      color: #fff;
      margin-bottom: 10px;
      text-align: center;
      transition: box-shadow 0.2s ease-in-out; }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-04 .landing-slide-model {
          margin-bottom: 20px; } }
      .landing-ageless .landing-slide-04 .landing-slide-model:hover {
        box-shadow: 0px 0px 46px rgba(0, 94, 204, 0.48); }
      .landing-ageless .landing-slide-04 .landing-slide-model-content {
        max-width: none;
        padding: 20px 10px 32px; }
        @media (min-width: 1024px) {
          .landing-ageless .landing-slide-04 .landing-slide-model-content {
            padding: 50px; } }
      .landing-ageless .landing-slide-04 .landing-slide-model-info {
        position: relative; }
      .landing-ageless .landing-slide-04 .landing-slide-model-image-wrapper {
        padding-bottom: 52.5%; }
        @media (min-width: 768px) {
          .landing-ageless .landing-slide-04 .landing-slide-model-image-wrapper {
            padding-bottom: 57.5%; } }
      .landing-ageless .landing-slide-04 .landing-slide-model-photo {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .landing-ageless .landing-slide-04 .landing-slide-model-name {
        margin-top: 18px;
        position: relative; }
        @media (min-width: 1260px) {
          .landing-ageless .landing-slide-04 .landing-slide-model-name {
            font-size: 26px; } }
      .landing-ageless .landing-slide-04 .landing-slide-model-buy-link {
        background-color: #005ecc;
        border-radius: 2px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        margin-top: 20px;
        padding: 0 22px;
        transition: background-color 0.2s; }
        @media (min-width: 1024px) {
          .landing-ageless .landing-slide-04 .landing-slide-model-buy-link {
            margin-top: 30px; } }
        .landing-ageless .landing-slide-04 .landing-slide-model-buy-link:hover {
          background-color: #004799; }
        .landing-ageless .landing-slide-04 .landing-slide-model-buy-link::before, .landing-ageless .landing-slide-04 .landing-slide-model-buy-link::after {
          display: none; }
  .landing-ageless.webp .landing-slide-model-photo.__legion-5-pro {
    background-image: url("https://lenovo.ua/proxy/694_407/ageless/models/legion-5-pro.webp"); }
  .landing-ageless.webp .landing-slide-model-photo.__legion-tower-5i {
    background-image: url("https://lenovo.ua/proxy/686_427/ageless/models/legion-tower-5i.webp"); }
  .landing-ageless.webp .landing-slide-model-photo.__legion-y25-25 {
    background-image: url("https://lenovo.ua/proxy/686_464/ageless/models/y25-25.webp"); }
  .landing-ageless.webp .landing-slide-model-photo.__legion-m600 {
    background-image: url("https://lenovo.ua/proxy/697_508/ageless/models/m600.webp"); }
  .landing-ageless.jpeg2000 .landing-slide-model-photo.__legion-5-pro, .landing-ageless.jpeg .landing-slide-model-photo.__legion-5-pro {
    background-image: url("https://lenovo.ua/proxy/694_407/ageless/models/legion-5-pro.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-model-photo.__legion-tower-5i, .landing-ageless.jpeg .landing-slide-model-photo.__legion-tower-5i {
    background-image: url("https://lenovo.ua/proxy/686_427/ageless/models/legion-tower-5i.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-model-photo.__legion-y25-25, .landing-ageless.jpeg .landing-slide-model-photo.__legion-y25-25 {
    background-image: url("https://lenovo.ua/proxy/686_464/ageless/models/y25-25.jpg"); }
  .landing-ageless.jpeg2000 .landing-slide-model-photo.__legion-m600, .landing-ageless.jpeg .landing-slide-model-photo.__legion-m600 {
    background-image: url("https://lenovo.ua/proxy/697_508/ageless/models/m600.jpg"); }
  .landing-ageless .landing-slide-05 {
    background: linear-gradient(180deg, #010101 0%, #1b1c23 41.33%, #000 100%);
    padding-bottom: 0; }
    @media (min-width: 1024px) {
      .landing-ageless .landing-slide-05 {
        padding-top: 0; } }
    .landing-ageless .landing-slide-05 .landing-slide-header {
      margin-bottom: 45px; }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-05 .landing-slide-header {
          margin-bottom: 60px; } }
    .landing-ageless .landing-slide-05 .contacts {
      padding-bottom: 30px;
      text-align: center; }
      @media (min-width: 1024px) {
        .landing-ageless .landing-slide-05 .contacts {
          padding-bottom: 35px; } }
      .landing-ageless .landing-slide-05 .contacts-email {
        color: #fff;
        display: inline-block;
        font-size: 20px;
        font-weight: 900;
        line-height: 1.2;
        margin-bottom: 25px;
        text-decoration: none;
        transition: opacity 0.4s ease-in-out; }
        @media all and (min-width: 375px) {
          .landing-ageless .landing-slide-05 .contacts-email {
            font-size: 22px; } }
        @media (min-width: 768px) {
          .landing-ageless .landing-slide-05 .contacts-email {
            font-size: 25px; } }
        @media (min-width: 1260px) {
          .landing-ageless .landing-slide-05 .contacts-email {
            font-size: 30px; } }
        .landing-ageless .landing-slide-05 .contacts-email:hover {
          opacity: .8; }
      .landing-ageless .landing-slide-05 .contacts-social a {
        align-items: center;
        color: #fff;
        display: inline-flex;
        height: 44px;
        justify-content: center;
        text-align: center;
        transition: opacity 0.4s ease-in-out;
        width: 44px; }
        @media (max-width: 767.98px) {
          .landing-ageless .landing-slide-05 .contacts-social a {
            margin: 0 calc(25% - 23px); } }
        @media (min-width: 768px) {
          .landing-ageless .landing-slide-05 .contacts-social a:nth-child(n + 2) {
            margin-left: 44px; } }
        .landing-ageless .landing-slide-05 .contacts-social a:hover {
          opacity: .8; }
        .landing-ageless .landing-slide-05 .contacts-social a .fa-facebook {
          font-size: 40px; }
        .landing-ageless .landing-slide-05 .contacts-social a .fa-instagram {
          font-size: 36px; }
    .landing-ageless .landing-slide-05 .partners {
      align-items: center;
      border-top: 1px solid #6c507e;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      max-width: 530px;
      padding-bottom: 25px;
      padding-top: 30px;
      text-align: center; }
      @media (min-width: 768px) {
        .landing-ageless .landing-slide-05 .partners {
          flex-direction: column;
          justify-content: center;
          padding-bottom: 40px;
          padding-top: 50px; } }
      @media (max-width: 767.98px) {
        .landing-ageless .landing-slide-05 .partners figure {
          width: 50%; } }
      .landing-ageless .landing-slide-05 .partners-legion {
        width: 106px; }
        @media (min-width: 768px) {
          .landing-ageless .landing-slide-05 .partners-legion {
            margin-bottom: 34px; } }
      .landing-ageless .landing-slide-05 .partners-gameinside {
        width: 167px; }
    .landing-ageless .landing-slide-05 .resources {
      align-items: center;
      border-top: 1px solid #6c507e;
      display: flex;
      height: 90px;
      justify-content: center;
      text-align: center; }
      .landing-ageless .landing-slide-05 .resources a {
        color: #fff;
        display: inline-block;
        font-size: 16px;
        position: relative; }
        .landing-ageless .landing-slide-05 .resources a:hover::after {
          width: 0; }
        .landing-ageless .landing-slide-05 .resources a::after {
          background-color: #fff;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          transition: width 0.4s ease-in-out;
          width: 100%; }
