$c-ageless-bg: #010101;
$c-ageless-accent: #6c507e;
$c-ageless-gradient-1: #010101;
$c-ageless-gradient-2: #1b1c23;
$c-ageless-button: #005ecc;
$c-ageless-model-bg: #242424;

.landing-ageless {
    background-color: $c-ageless-bg;
    color: $c-light;
    cursor:url("#{$assets-url}/portal/images/landing/ageless/svg/cursor.svg") 18 18, pointer;
    font-family: $font-legion;

    a,
    button,
    .link-to-top {
        cursor:url("#{$assets-url}/portal/images/landing/ageless/svg/cursor-active.svg") 18 18, pointer;
    }

    main {
        margin-top: 0;
        padding-top: 0;
    }

    .container {
        position: relative;

        @include xl {
            max-width: calc(100% - 200px);
        }

        @include media-min(1700) {
            max-width: 1660px;
        }
    }

    .header {
        background-color: $c-none;
        height: 80px;
        position: relative;

        @include lg {
            height: 100px;
        }

        @include media-min(1800) {
            height: 155px;
        }

        &-container {
            height: 100%;

            @include media-breakpoint-down(md) {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            @include media-max(1600) {
                max-width: 100%;
            }
        }

        .ageless-logo {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            height: 54px;
            width: 140px;

            @include media-breakpoint-down(md) {
                left: 10px;
                position: absolute;
                top: 20px;
            }

            @include sm {
                height: 70px;
                width: 187px;
            }
            
            @include lg {
                height: 94px;
                width: 250px;
            }

            @include media-min(1800) {
                height: 135px;
                width: 360px;
            }
        }

        .lenovo-logo {
            color: $c-light;

            @include lg {
                display: none;
            }

            svg {
                height: 25px;
                width: 126px;

                @include sm {
                    height: 35px;
                    width: 177px;
                }

                path,
                polygon {
                    fill: $c-light;
                }
            }
        }

        &-nav {
            padding: 0;

            @include media-breakpoint-down(md) {
                background: linear-gradient(180deg, #170025 -100%, #000825 -57.96%, #001641 -23.71%, #121519 51.54%, #000000 94.13%), #FFFFFF;;
                display: block;
                height: 0;
                height: 100vh;
                left: 0;
                opacity: 0;
                padding: 50px;
                position: absolute;
                top: 0;
                transition: opacity $time ease-in-out;
                visibility: hidden;
                width: 100%;
                z-index: 98;
            }

            @include lg {
                height: 100%;
            }

            &::before,
            &::after {
                @include md {
                    background-color: $c-ageless-accent;
                    content: '';
                    display: block;
                    height: 1px;
                    position: absolute;
                    top: 50%;
                }
            }

            &::before {
                @include xl {
                    left: 0;
                    width: 100px;
                }

                @include media-min(1800) {
                    width: 260px;
                }
            }

            &::after {
                @include xl {
                    right: 100px;
                    width: 100px;
                }

                @include media-min(1800) {
                    right: 100px;
                    width: 160px;
                }
            }

            &.open {
                opacity: 1;
                visibility: visible;

                &.show {
                    .header-nav-menu-item {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    .header-nav-lang {
                        opacity: 1;
                    }

                    &.hide {
                        .header-nav-menu-item {
                            opacity: 0;
                            transform: translateX(100px);
                        }

                        .header-nav-lang {
                            opacity: 0;
                        }
                    }
                }

                .header-nav-menu {
                    height: 100%;
                }

                &.animate {
                    .header-nav-menu-item {
                        @for $i from 2 through 5 {
                            &:nth-child(#{$i}) {
                                transition: opacity .2s #{$i * .1}s, transform .2s #{$i * .1}s;
                            }
                        }
                    }
                }
            }

            &-menu {
                background-color: $c-none;
                display: flex;
                justify-content: center;
                opacity: 1;
                overflow: visible;
                padding: 0;
                position: static;
                transform: none;

                &-item {
                    @include media-breakpoint-down(md) {
                        opacity: 0;
                        transform: translateX(-100px);

                        &:nth-child(n + 3) {
                            margin-top: 44px;
                        }
                    }

                    @include lg {
                        margin-left: 30px;

                        &:nth-child(1) {
                            order: 3;
                        }

                        &:nth-child(2) {
                            margin-left: 0;
                            order: 1;
                        }

                        &:nth-child(3) {
                            order: 2;
                        }

                        &:nth-child(4) {
                            order: 4;
                        }

                        &:nth-child(5) {
                            order: 5;
                        }
                    }

                    @include media-min(1600) {
                        margin-left: 60px;
                    }

                    &.header-logo {
                        @include lg {
                            order: 3;
                        }
                    }

                    a {
                        color: $c-light;
                        font-size: 22px;
                        line-height: 1.2;
                        position: relative;
                        text-transform: uppercase;

                        @include lg {
                            font-size: 16px;
                        }

                        &:hover {
                            &::after {
                                width: 100%;
                            }
                        }

                        &::after {
                            background-color: $c-light;
                            bottom: 0;
                            content: '';
                            display: block;
                            height: 1px;
                            left: 0;
                            position: absolute;
                            transition: width $time ease-in-out;
                            width: 0;
                        }
                    }
                }
            }

            &-lang {
                align-items: center;
                display: flex;

                @include media-breakpoint-down(md) {
                    justify-content: center;
                    margin-top: 20px;
                    opacity: 0;
                    transition: opacity $time $time * 4 ease-in-out;
                }

                @include lg {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &-item {
                    border-radius: 4px;
                    font-size: 16px;
                    line-height: 1.2;
                    padding: 6px;
                    text-transform: uppercase;

                    &.__active {
                        background-color: $c-light;
                        color: $c-ageless-accent;
                    }

                    a {
                        color: $c-light;
                    }
                }
            }
        }

        .burger {
            background-color: transparent;
            border: 0;
            height: 26px;
            padding: 0;
            position: relative;
            width: 26px;
            z-index: 99;

            @include md {
                height: 35px;
                width: 40px;
            }

            @include lg {
                display: none;
            }

            &.open span {
                &:nth-child(1) {
                    left: 50%;
                    top: 18px;
                    width: 0;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    left: 50%;
                    top: 18px;
                    width: 0;
                }
            }

            span {
                background: $c-light;
                display: block;
                height: 4px;
                left: 0;
                opacity: 1;
                position: absolute;
                transform: rotate(0deg);
                transition: $time ease-in-out;
                width: 100%;

                &:nth-child(1) {
                    top: 3px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 10px;

                    @include md {
                        top: 15px;
                    }
                }

                &:nth-child(4) {
                    top: 17px;

                    @include md {
                        top: 26px;
                    }
                }
            }
        }
    }

    &.webp {
        .ageless-logo {
            background-image: url("#{$aws-lambda}/360_135/ageless/ageless-shooters-logo.webp");
        }
    }

    &.jpeg2000,
    &.jpeg {
        .ageless-logo {
            background-image: url("#{$aws-lambda}/360_135/ageless/ageless-shooters-logo.png");
        }
    }

    .landing-slide {
        padding: 60px 0;

        @include lg {
            padding: 100px 0;
        }

        @include xl {
            padding: 120px 0;
        }

        &-header {
            font-size: 40px;
            font-weight: 900;
            line-height: 1.2;
            margin-bottom: 60px;
            position: relative;
            text-align: center;

            @include media-min(1600) {
                font-size: 60px;
                margin-bottom: 90px;
            }

            &::before,
            &::after {
                @include md {
                    background-color: $c-ageless-accent;
                    content: '';
                    display: block;
                    height: 1px;
                    position: absolute;
                    top: 50%;
                    width: 150px;
                }

                @include lg {
                    width: 150px;
                }

                @include xl {
                    width: 200px;
                }

                @include media-min(1600) {
                    width: 250px;
                }

                @include media-min(1800) {
                    width: 336px;
                }
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }
    }

    .landing-slide-01 {
        background: linear-gradient(180deg, rgba($c-default, .38) 0%, rgba($c-default, 0) 30.49%, rgba($c-default, .3) 52.41%, $c-default 75.75%);
        padding: 70px 0;
        text-align: center;

        @include sm {
            padding-top: 150px;
        }

        @include md {
            background-position: top center;
            background-repeat: no-repeat;
            background-size: contain;
            padding-top: 400px;
        }
        
        @include lg {
            background-position: top center;
            background-size: cover;
            height: 100vh;
            margin-top: -100px;
            padding-bottom: 0;
            padding-top: 350px;
        }

        @include xl {
            padding-top: 450px;
        }

        @include media-min(1600) {
            padding-top: 65vh;
        }

        @include media-min(1800) {
            margin-top: -125px;
        }

        &-header {
            font-size: 24px;
            font-weight: 900;
            line-height: 1.1;
            margin-bottom: 20px;

            @include media-min(375) {
                font-size: 34px;
            }

            @include lg {
                font-size: 40px;
                margin-bottom: 12px;
            }

            @include xl {
                font-size: 45px;
            }

            @include media-min(1600) {
                font-size: 55px;
            }

            @include media-min(1800) {
                font-size: 70px;
            }
        }

        &-subheader {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 60px;

            @include media-min(375) {
                font-size: 18px;
            }

            @include lg {
                margin-bottom: 30px;
            }

            @include media-min(1600) {
                font-size: 26px;
                margin-bottom: 42px;
            }
        }

        .ageless-logo {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            height: 120px;
            margin-bottom: 20px;
            width: 288px;

            @include md {
                height: 135px;
                margin-bottom: 50px;
                width: 365px;
            }

            @include lg {
                display: none;
            }
        }

        .legion-logo {
            width: 120px;

            @include sm {
                width: 150px;
            }

            @include lg {
                width: auto;
            }
        }
    }

    &.webp {
        .landing-slide-01 {
            @include md {
                background-image: url("#{$aws-lambda}/1920_1010/ageless/s01-bg.webp");
            }

            .ageless-logo {
                background-image: url("#{$aws-lambda}/360_135/ageless/ageless-shooters-logo.webp");
            }
        }
    }

    &.jpeg2000,
    &.jpeg {
        .landing-slide-01 {
            @include lg {
                background-image: url("#{$aws-lambda}/1920_1010/ageless/s01-bg.jpg");
            }

            .ageless-logo {
                background-image: url("#{$aws-lambda}/360_135/ageless/ageless-shooters-logo.png");
            }
        }
    }

    .landing-slide-02 {
        background: linear-gradient(180deg, $c-ageless-gradient-1 0%, $c-ageless-gradient-2 41.33%, $c-default 100%);

        @include media-breakpoint-down(sm) {
            padding-bottom: 25px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 70px;
        }

        @include xl {
            padding-bottom: 45px;
        }

        .team {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;

            &-player {
                flex: 0 0 50%;
                margin-bottom: 70px;
                max-width: 50%;

                @include lg {
                    flex: 0 0 33.33333%;
                    margin-bottom: 60px;
                    max-width: 33.33333%;
                }

                @include media-max(1700) {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                @include media-min(1700) {
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                &:hover {
                    .team-player-avatar::after {
                        animation: shine .75s;
                    }
                }

                &-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;
                    padding: 0 10px;
                }

                &-avatar {
                    margin-bottom: 17px;
                    overflow: hidden;
                    padding-bottom: 96.812%;
                    position: relative;

                    @include lg {
                        padding-bottom: 84.812%;
                    }

                    &::before {
                        background-position: center;
                        background-size: cover;
                        content: '';
                        display: block;
                        height: 100%;
                        left: 0;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }

                    &::after {
                        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
                        content: '';
                        display: block;
                        height: 100%;
                        left: -75%;
                        position: absolute;
                        top: 0;
                        transform: skewX(-25deg);
                        width: 50%;
                        z-index: 2;
                    }
                }

                @keyframes shine {
                    100% {
                        left: 125%;
                    }
                }

                &-name {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.2;
                    margin-bottom: 20px;
                }

                &-profession {
                    display: none;

                    @include md {
                        display: flex;
                        font-size: 14px;
                        line-height: 1.4;
                        margin-bottom: 20px;
                    }

                    &-title {
                        font-weight: 700;
                        margin-right: 4px;
                    }
                }

                &-hobby {
                    display: none;

                    @include md {
                        display: flex;
                        font-size: 14px;
                        line-height: 1.4;
                        margin-bottom: 20px;
                    }

                    &-title {
                        font-weight: 700;
                        margin-right: 4px;
                    }
                }

                &-stats {
                    border-bottom: 1px solid $c-ageless-accent;
                    border-top: 1px solid $c-ageless-accent;
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    padding: 12px 0;

                    @include md {
                        align-items: stretch;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    &-item {
                        align-items: center;
                        display: flex;

                        &:nth-child(n + 2) {
                            margin-top: 10px;

                            @include md {
                                margin-left: 5px;
                                margin-top: 0;
                            }
                        }
                    }

                    &-icon {
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: inline-block;
                        flex: 0 0 26px;
                        height: 26px;
                        margin-right: 10px;
                        width: 26px;
                        
                        &.hours {
                            background-image: url("#{$assets-url}/portal/images/landing/ageless/svg/icon-clock.svg");
                        }

                        &.wins {
                            background-image: url("#{$assets-url}/portal/images/landing/ageless/svg/icon-trophy.svg");
                        }

                        &.crosshair {
                            background-image: url("#{$assets-url}/portal/images/landing/ageless/svg/icon-crosshair.svg");
                        }
                    }

                    &-txt {
                        line-height: 1.5;
                    }

                    &-title {
                        font-size: 14px;
                    }

                    &-digits {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &.webp {
        .landing-slide-02 .team-player-avatar {
            &.rocinrol::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/rocinrol.webp");
            }

            &.valkyria::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/valkyria.webp");
            }

            &.archer::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/archer.webp");
            }

            &.taskor::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/taskor.webp");
            }

            &.ukrop::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/ukrop.webp");
            }

            &.silentsleep::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/silentsleep.webp");
            }

            &.hammer::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/hammer.webp");
            }
        }
    }

    &.jpeg2000,
    &.jpeg {
        .landing-slide-02 .team-player-avatar {
            &.rocinrol::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/rocinrol.jpg");
            }

            &.valkyria::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/valkyria.jpg");
            }

            &.archer::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/archer.jpg");
            }

            &.taskor::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/taskor.jpg");
            }

            &.ukrop::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/ukrop.jpg");
            }

            &.silentsleep::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/silentsleep.jpg");
            }

            &.hammer::before {
                background-image: url("#{$aws-lambda}/400_335/ageless/team/hammer.jpg");
            }
        }
    }

    .landing-slide-03 {
        padding-top: 25px;

        @include md {
            padding-top: 60px;
        }

        @include xl {
            padding-top: 105px;
        }

        @include media-min(1600) {
            padding-bottom: 80px;
        }

        .landing-slide-header {
            @include media-breakpoint-down(sm) {
                margin-bottom: 25px;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.6;

            @include md {
                font-size: 20px;
            }

            @include lg {
                font-size: 26px;
                line-height: 1.5;
            }
        }

        .photos {
            margin: 30px auto;
            overflow: hidden;
            text-align: center;

            @include lg {
                margin: 58px auto;
            }

            &-wrapper {
                display: flex;
                flex-wrap: nowrap;
            }

            &-container {
                display: flex;
                flex-wrap: nowrap;
            }

            picture,
            img {
                height: 153px;
                margin-left: 9px;
                width: auto;

                @include md {
                    height: 240px;
                    margin-left: 15px;
                }

                @include lg {
                    height: 360px;
                    margin-left: 23px;
                }
            }
        }
    }

    .landing-slide-04 {
        background: linear-gradient(180deg, $c-ageless-gradient-1 0%, $c-ageless-gradient-2 41.33%, $c-default 100%);

        @include media-min(1600) {
            padding-bottom: 80px;
        }

        .landing-slide-header {
            &::before,
            &::after {
                @include md {
                    width: 120px;
                }

                @include lg {
                    width: 100px;
                }

                @include xl {
                    width: 120px;
                }

                @include media-min(1700) {
                    width: 200px;
                }
            }
        }

        .landing-slide-model {
            background-color: $c-ageless-model-bg;
            color: $c-light;
            margin-bottom: 10px;
            text-align: center;
            transition: box-shadow $time ease-in-out;

            @include lg {
                margin-bottom: 20px;
            }

            &:hover {
                box-shadow: 0px 0px 46px rgba(0, 94, 204, .48);
            }

            &-content {
                max-width: none;
                padding: 20px 10px 32px;

                @include lg {
                    padding: 50px;
                }
            }

            &-info {
                position: relative;
            }

            &-image {
                &-wrapper {
                    padding-bottom: 52.5%;

                    @include md {
                        padding-bottom: 57.5%;
                    }
                }
            }

            &-photo {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            &-name {
                margin-top: 18px;
                position: relative;

                @include xl {
                    font-size: 26px;
                }
            }
            
            &-buy {
                &-link {
                    background-color: $c-ageless-button;
                    border-radius: 2px;
                    color: $c-light;
                    display: inline-block;
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    margin-top: 20px;
                    padding: 0 22px;
                    transition: background-color $time;

                    @include lg {
                        margin-top: 30px;
                    }

                    &:hover {
                        background-color: darken($c-ageless-button, 10%);
                    }

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &.webp {
        .landing-slide-model-photo {
            &.__legion-5-pro {
                background-image: url("#{$aws-lambda}/694_407/ageless/models/legion-5-pro.webp");
            }

            &.__legion-tower-5i {
                background-image: url("#{$aws-lambda}/686_427/ageless/models/legion-tower-5i.webp");
            }

            &.__legion-y25-25 {
                background-image: url("#{$aws-lambda}/686_464/ageless/models/y25-25.webp");
            }

            &.__legion-m600 {
                background-image: url("#{$aws-lambda}/697_508/ageless/models/m600.webp");
            }
        }
    }

    &.jpeg2000,
    &.jpeg {
        .landing-slide-model-photo {
            &.__legion-5-pro {
                background-image: url("#{$aws-lambda}/694_407/ageless/models/legion-5-pro.jpg");
            }

            &.__legion-tower-5i {
                background-image: url("#{$aws-lambda}/686_427/ageless/models/legion-tower-5i.jpg");
            }

            &.__legion-y25-25 {
                background-image: url("#{$aws-lambda}/686_464/ageless/models/y25-25.jpg");
            }

            &.__legion-m600 {
                background-image: url("#{$aws-lambda}/697_508/ageless/models/m600.jpg");
            }
        }
    }

    .landing-slide-05 {
        background: linear-gradient(180deg, $c-ageless-gradient-1 0%, $c-ageless-gradient-2 41.33%, $c-default 100%);
        padding-bottom: 0;

        @include lg {
            padding-top: 0;
        }

        .landing-slide-header {
            margin-bottom: 45px;

            @include lg {
                margin-bottom: 60px;
            }
        }
        
        .contacts {
            padding-bottom: 30px;
            text-align: center;

            @include lg {
                padding-bottom: 35px;
            }

            &-email {
                color: $c-light;
                display: inline-block;
                font-size: 20px;
                font-weight: 900;
                line-height: 1.2;
                margin-bottom: 25px;
                text-decoration: none;
                transition: opacity $time * 2 ease-in-out;

                @include media-min(375) {
                    font-size: 22px;
                }

                @include md {
                    font-size: 25px;
                }

                @include xl {
                    font-size: 30px;
                }

                &:hover {
                    opacity: .8;
                }
            }

            &-social {
                a {
                    align-items: center;
                    color: $c-light;
                    display: inline-flex;
                    height: 44px;
                    justify-content: center;
                    text-align: center;
                    transition: opacity $time * 2 ease-in-out;
                    width: 44px;

                    @include media-breakpoint-down(sm) {
                        margin: 0 calc(25% - 23px);
                    }

                    &:nth-child(n + 2) {
                        @include md {
                            margin-left: 44px;
                        }
                    }

                    &:hover {
                        opacity: .8;
                    }

                    .fa-facebook {
                        font-size: 40px;
                    }

                    .fa-instagram {
                        font-size: 36px;
                    }
                }
            }
        }

        .partners {
            align-items: center;
            border-top: 1px solid $c-ageless-accent;
            display: flex;
            justify-content: space-around;
            margin: 0 auto;
            max-width: 530px;
            padding-bottom: 25px;
            padding-top: 30px;
            text-align: center;

            @include md {
                flex-direction: column;
                justify-content: center;
                padding-bottom: 40px;
                padding-top: 50px;
            }

            figure {
                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }

            &-legion {
                width: 106px;

                @include md {
                    margin-bottom: 34px;
                }
            }

            &-gameinside {
                width: 167px;
            }
        }

        .resources {
            align-items: center;
            border-top: 1px solid $c-ageless-accent;
            display: flex;
            height: 90px;
            justify-content: center;
            text-align: center;

            a {
                color: $c-light;
                display: inline-block;
                font-size: 16px;
                position: relative;

                &:hover {
                    &::after {
                        width: 0;
                    }
                }

                &::after {
                    background-color: $c-light;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 0;
                    position: absolute;
                    transition: width $time * 2 ease-in-out;
                    width: 100%;
                }

            }

        }
    }
}
